var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.newAllergyTextModalVisibility,
            callback: function ($$v) {
              _vm.newAllergyTextModalVisibility = $$v
            },
            expression: "newAllergyTextModalVisibility",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Please provide allergy text "),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Allergy text" },
                    model: {
                      value: _vm.tempAllergyText,
                      callback: function ($$v) {
                        _vm.tempAllergyText = $$v
                      },
                      expression: "tempAllergyText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAndSelectAllergyText(
                            _vm.tempAllergyText
                          )
                        },
                      },
                    },
                    [_vm._v(" Save & Select ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.newAllergyTextModalVisibility = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Global options"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticClass: "pl-8" },
        [
          _c("checkbox-row", {
            attrs: {
              title: "Order at Table",
              tooltip: "Will this be an Order at Table (OAT) location?",
            },
            model: {
              value: _vm.value.orderAtTable,
              callback: function ($$v) {
                _vm.$set(_vm.value, "orderAtTable", $$v)
              },
              expression: "value.orderAtTable",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Virtual Kiosk (VK)",
              tooltip: "Will this site include Virtual Kiosks?",
            },
            model: {
              value: _vm.value.virtualKiosk,
              callback: function ($$v) {
                _vm.$set(_vm.value, "virtualKiosk", $$v)
              },
              expression: "value.virtualKiosk",
            },
          }),
          _c("radio-group-row", {
            attrs: {
              title: "Tab",
              items: [
                { value: "openCheck", label: "Open check" },
                { value: "closedCheck", label: "Closed check" },
              ],
            },
            model: {
              value: _vm.value.tab,
              callback: function ($$v) {
                _vm.$set(_vm.value, "tab", $$v)
              },
              expression: "value.tab",
            },
          }),
          _vm.value.tab === "openCheck"
            ? _c("text-field-row", {
                attrs: {
                  title: "Open tab threshold (tab maximum)",
                  tooltip:
                    "What is the tab threshold you do not want the guest to exceed? Ex: If their order exceeds $250.00, inhibit placing order and prompt payment.",
                },
                model: {
                  value: _vm.value.openTabThreshold,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "openTabThreshold", $$v)
                  },
                  expression: "value.openTabThreshold",
                },
              })
            : _vm._e(),
          _vm.value.tab === "openCheck"
            ? _c("text-field-row", {
                attrs: {
                  title: "Open tab auto close time (in minutes)",
                  tooltip:
                    "What is the amount of time, after the last update on the tab, that you would like to close out? Ex: If an open tab has not had activity in 2 hours.",
                  required: "",
                },
                model: {
                  value: _vm.value.openTabAutoCloseTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "openTabAutoCloseTime", $$v)
                  },
                  expression: "value.openTabAutoCloseTime",
                },
              })
            : _vm._e(),
          _vm.value.tab === "closedCheck"
            ? _c("checkbox-row", {
                attrs: {
                  title: "Query POS for any existing open checks on table",
                },
                model: {
                  value: _vm.value.queryPOSForAnyCheck,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "queryPOSForAnyCheck", $$v)
                  },
                  expression: "value.queryPOSForAnyCheck",
                },
              })
            : _vm._e(),
          _c("checkbox-row", {
            attrs: {
              title: "Force e-mail and text collection before payment?",
              tooltip:
                "Would you like the site to force e-mail and text collection before the guest pays?",
            },
            model: {
              value: _vm.value.forceEmailAndTextCollectionBeforePayment,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value,
                  "forceEmailAndTextCollectionBeforePayment",
                  $$v
                )
              },
              expression: "value.forceEmailAndTextCollectionBeforePayment",
            },
          }),
          _c("text-field-row", {
            attrs: { title: "Requested URL", required: "" },
            model: {
              value: _vm.value.requestedUrl,
              callback: function ($$v) {
                _vm.$set(_vm.value, "requestedUrl", $$v)
              },
              expression: "value.requestedUrl",
            },
          }),
          _c("text-field-row", {
            attrs: {
              title: "Google Analytics",
              tooltip: "Will the site monitor website traffic?",
            },
            model: {
              value: _vm.value.googleAnalytics,
              callback: function ($$v) {
                _vm.$set(_vm.value, "googleAnalytics", $$v)
              },
              expression: "value.googleAnalytics",
            },
          }),
          _c("file-input-row", {
            attrs: {
              title: "Site Logo",
              "input-label": "Site Logo (svg preferred)",
              required: "",
            },
            model: {
              value: _vm.value.siteLogo,
              callback: function ($$v) {
                _vm.$set(_vm.value, "siteLogo", $$v)
              },
              expression: "value.siteLogo",
            },
          }),
          _c("file-input-row", {
            attrs: {
              title: "Receipt Logo",
              "input-label": "Receipt Logo (svg preferred)",
              required: "",
            },
            model: {
              value: _vm.value.receiptLogo,
              callback: function ($$v) {
                _vm.$set(_vm.value, "receiptLogo", $$v)
              },
              expression: "value.receiptLogo",
            },
          }),
          _c("checkbox-row", {
            attrs: { title: "Auto receipt" },
            model: {
              value: _vm.value.autoReceipt,
              callback: function ($$v) {
                _vm.$set(_vm.value, "autoReceipt", $$v)
              },
              expression: "value.autoReceipt",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Text receipt",
              tooltip:
                "Will the site deliver a receipt via text? This will require the system to prompt for the users phone number",
            },
            model: {
              value: _vm.value.textReceipt,
              callback: function ($$v) {
                _vm.$set(_vm.value, "textReceipt", $$v)
              },
              expression: "value.textReceipt",
            },
          }),
          _c(
            "field-row",
            { attrs: { title: "Allergy text to display" } },
            [
              _c("v-select", {
                attrs: {
                  label: "Allergy text to display",
                  items: _vm.allergyTextItems,
                  attach: "",
                  dense: "",
                  required: "",
                  rules: [(v) => !!v || "Allergy text to display is required!"],
                },
                on: { change: _vm.onAllergyUpdate },
                model: {
                  value: _vm.value.allergyText,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "allergyText", $$v)
                  },
                  expression: "value.allergyText",
                },
              }),
            ],
            1
          ),
          _c("checkbox-row", {
            attrs: {
              title: "Display US calorie information on launch screen?",
              "input-label": "Display US calorie",
            },
            model: {
              value: _vm.value.displayUSCalorie,
              callback: function ($$v) {
                _vm.$set(_vm.value, "displayUSCalorie", $$v)
              },
              expression: "value.displayUSCalorie",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Tables enabled",
              tooltip: "Will tables be enabled at your location?",
            },
            model: {
              value: _vm.value.tablesEnabled,
              callback: function ($$v) {
                _vm.$set(_vm.value, "tablesEnabled", $$v)
              },
              expression: "value.tablesEnabled",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Tipping enabled",
              tooltip: "Will tipping be enabled at this location?",
            },
            model: {
              value: _vm.value.tippingEnabled,
              callback: function ($$v) {
                _vm.$set(_vm.value, "tippingEnabled", $$v)
              },
              expression: "value.tippingEnabled",
            },
          }),
          _vm.value.tippingEnabled
            ? _c(
                "field-row",
                {
                  attrs: {
                    title: "Default 3 tip % presets",
                    tooltip:
                      "What are the 3 preset tip amounts that the user can select from",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Default 3 tip % presets",
                              clearable: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.value.defaultTips[0],
                              callback: function ($$v) {
                                _vm.$set(_vm.value.defaultTips, 0, $$v)
                              },
                              expression: "value.defaultTips[0]",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Default 3 tip % presets",
                              clearable: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.value.defaultTips[1],
                              callback: function ($$v) {
                                _vm.$set(_vm.value.defaultTips, 1, $$v)
                              },
                              expression: "value.defaultTips[1]",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Default 3 tip % presets",
                              clearable: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.value.defaultTips[2],
                              callback: function ($$v) {
                                _vm.$set(_vm.value.defaultTips, 2, $$v)
                              },
                              expression: "value.defaultTips[2]",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value.tippingEnabled && _vm.isHMSHost
            ? _c("checkbox-row", {
                attrs: {
                  title: "Tipping at close only",
                  tooltip:
                    "Only prompt for tip when the user has selected to close out tab. Tip field will not be displayed on cart/basket page.",
                },
                model: {
                  value: _vm.value.tippingAtCloseOnly,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "tippingAtCloseOnly", $$v)
                  },
                  expression: "value.tippingAtCloseOnly",
                },
              })
            : _vm._e(),
          _c("checkbox-row", {
            attrs: {
              title: "Auto add tip on auto close",
              tooltip:
                "Will the system auto add a tip if the system auto closes a check?",
            },
            model: {
              value: _vm.value.autoAddTipOnClose,
              callback: function ($$v) {
                _vm.$set(_vm.value, "autoAddTipOnClose", $$v)
              },
              expression: "value.autoAddTipOnClose",
            },
          }),
          _vm.value.autoAddTipOnClose
            ? _c("text-field-row", {
                attrs: {
                  title: "Auto close tip %",
                  tooltip:
                    "If the tab auto closes, what tip percentage would you like automatically added to the tab?",
                },
                model: {
                  value: _vm.value.autoCloseTip,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "autoCloseTip", $$v)
                  },
                  expression: "value.autoCloseTip",
                },
              })
            : _vm._e(),
          _c("checkbox-row", {
            attrs: {
              title: "Employee Discount",
              tooltip: "Will employee discounts be enabled at this location?",
            },
            model: {
              value: _vm.value.employeeDiscount,
              callback: function ($$v) {
                _vm.$set(_vm.value, "employeeDiscount", $$v)
              },
              expression: "value.employeeDiscount",
            },
          }),
          _vm.value.employeeDiscount && _vm.isSSP
            ? _c("checkbox-row", {
                attrs: {
                  title: "Display discount advertisement on launch screen",
                },
                model: {
                  value: _vm.value.displayDiscountAdvertisement,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "displayDiscountAdvertisement", $$v)
                  },
                  expression: "value.displayDiscountAdvertisement",
                },
              })
            : _vm._e(),
          _c("checkbox-row", {
            attrs: { title: "Existing tab advertisement" },
            model: {
              value: _vm.value.existingTabAdvertisement,
              callback: function ($$v) {
                _vm.$set(_vm.value, "existingTabAdvertisement", $$v)
              },
              expression: "value.existingTabAdvertisement",
            },
          }),
          _c("checkbox-row", {
            attrs: { title: "Auto receipt" },
            model: {
              value: _vm.value.autoReceipt,
              callback: function ($$v) {
                _vm.$set(_vm.value, "autoReceipt", $$v)
              },
              expression: "value.autoReceipt",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Text receipt",
              tooltip:
                "Will the site deliver a receipt via text? This will require the system to prompt for the users phone number",
            },
            model: {
              value: _vm.value.textReceipt,
              callback: function ($$v) {
                _vm.$set(_vm.value, "textReceipt", $$v)
              },
              expression: "value.textReceipt",
            },
          }),
          _c("checkbox-row", {
            attrs: {
              title: "Static Upsell and Add on",
              tooltip:
                "Will upsell and Add on displayed be dynamic or static. Dynamic is recommended.",
            },
            model: {
              value: _vm.value.staticUpsellAndAddOn,
              callback: function ($$v) {
                _vm.$set(_vm.value, "staticUpsellAndAddOn", $$v)
              },
              expression: "value.staticUpsellAndAddOn",
            },
          }),
          _c("checkbox-row", {
            attrs: { title: "Is this a production store (not lab)" },
            model: {
              value: _vm.value.isProductionStore,
              callback: function ($$v) {
                _vm.$set(_vm.value, "isProductionStore", $$v)
              },
              expression: "value.isProductionStore",
            },
          }),
          _c("file-input-row", {
            attrs: { title: "Email Receipt Logo" },
            model: {
              value: _vm.value.emailReceiptLogo,
              callback: function ($$v) {
                _vm.$set(_vm.value, "emailReceiptLogo", $$v)
              },
              expression: "value.emailReceiptLogo",
            },
          }),
          _c("radio-group-row", {
            attrs: {
              items: [
                { value: "surveyLink", label: "Survey Link" },
                { value: "starRating", label: "Star Rating" },
              ],
              title: "Thank You page",
            },
            model: {
              value: _vm.value.surveyLinkOrStarRating,
              callback: function ($$v) {
                _vm.$set(_vm.value, "surveyLinkOrStarRating", $$v)
              },
              expression: "value.surveyLinkOrStarRating",
            },
          }),
          _vm.value.surveyLinkOrStarRating === "surveyLink"
            ? _c("text-field-row", {
                attrs: { title: "Survey URL" },
                model: {
                  value: _vm.value.surveyUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "surveyUrl", $$v)
                  },
                  expression: "value.surveyUrl",
                },
              })
            : _vm._e(),
          _c("text-field-row", {
            attrs: { title: "Privacy URL" },
            model: {
              value: _vm.value.privacyUrl,
              callback: function ($$v) {
                _vm.$set(_vm.value, "privacyUrl", $$v)
              },
              expression: "value.privacyUrl",
            },
          }),
          _c("text-field-row", {
            attrs: { title: "Terms URL" },
            model: {
              value: _vm.value.termsUrl,
              callback: function ($$v) {
                _vm.$set(_vm.value, "termsUrl", $$v)
              },
              expression: "value.termsUrl",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }