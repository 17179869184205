<template>
    <v-expansion-panel
        v-on="$listeners"
    >
        <v-dialog
            v-model="newAllergyTextModalVisibility"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Please provide allergy text
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        label="Allergy text"
                        v-model="tempAllergyText"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveAndSelectAllergyText(tempAllergyText)"
                    >
                        Save & Select
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="newAllergyTextModalVisibility = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Global options</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pl-8">
            <checkbox-row
                title="Order at Table"
                tooltip="Will this be an Order at Table (OAT) location?"
                v-model="value.orderAtTable"
            />
            <checkbox-row
                title="Virtual Kiosk (VK)"
                tooltip="Will this site include Virtual Kiosks?"
                v-model="value.virtualKiosk"
            />
            <radio-group-row
                v-model="value.tab"
                title="Tab"
                :items="[{value: 'openCheck', label: 'Open check'}, {value: 'closedCheck', label: 'Closed check'}]"
            />
            <text-field-row
                v-if="value.tab === 'openCheck'"
                v-model="value.openTabThreshold"
                title="Open tab threshold (tab maximum)"
                tooltip="What is the tab threshold you do not want the guest to exceed? Ex: If their order exceeds $250.00, inhibit placing order and prompt payment."
            />
            <text-field-row
                v-if="value.tab === 'openCheck'"
                title="Open tab auto close time (in minutes)"
                tooltip="What is the amount of time, after the last update on the tab, that you would like to close out? Ex: If an open tab has not had activity in 2 hours."
                v-model="value.openTabAutoCloseTime"
                required
            />
            <checkbox-row
                v-if="value.tab === 'closedCheck'"
                title="Query POS for any existing open checks on table"
                v-model="value.queryPOSForAnyCheck"
            />
            <checkbox-row
                title="Force e-mail and text collection before payment?"
                tooltip="Would you like the site to force e-mail and text collection before the guest pays?"
                v-model="value.forceEmailAndTextCollectionBeforePayment"
            />
            <text-field-row
                title="Requested URL"
                v-model="value.requestedUrl"
                required
            />
            <text-field-row
                title="Google Analytics"
                tooltip="Will the site monitor website traffic?"
                v-model="value.googleAnalytics"
            />
            <file-input-row
                v-model="value.siteLogo"
                title="Site Logo"
                input-label="Site Logo (svg preferred)"
                required
            />
            <file-input-row
                v-model="value.receiptLogo"
                title="Receipt Logo"
                input-label="Receipt Logo (svg preferred)"
                required
            />
            <checkbox-row
                title="Auto receipt"
                v-model="value.autoReceipt"
            />
            <checkbox-row
                title="Text receipt"
                tooltip="Will the site deliver a receipt via text? This will require the system to prompt for the users phone number"
                v-model="value.textReceipt"
            />
            <field-row title="Allergy text to display">
                <v-select
                    label="Allergy text to display"
                    v-model="value.allergyText"
                    :items="allergyTextItems"
                    @change="onAllergyUpdate"
                    attach
                    dense
                    required
                    :rules="[v => !!v || 'Allergy text to display is required!']"
                />
            </field-row>
            <checkbox-row
                v-model="value.displayUSCalorie"
                title="Display US calorie information on launch screen?"
                input-label="Display US calorie"
            />
            <checkbox-row
                v-model="value.tablesEnabled"
                title="Tables enabled"
                tooltip="Will tables be enabled at your location?"
            />
            <checkbox-row
                v-model="value.tippingEnabled"
                title="Tipping enabled"
                tooltip="Will tipping be enabled at this location?"
            />
            <field-row
                v-if="value.tippingEnabled"
                title="Default 3 tip % presets"
                tooltip="What are the 3 preset tip amounts that the user can select from"
            >
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="value.defaultTips[0]"
                            label="Default 3 tip % presets"
                            clearable
                            dense
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="value.defaultTips[1]"
                            label="Default 3 tip % presets"
                            clearable
                            dense
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="value.defaultTips[2]"
                            label="Default 3 tip % presets"
                            clearable
                            dense
                        />
                    </v-col>
                </v-row>
            </field-row>
            <checkbox-row
                v-if="value.tippingEnabled && isHMSHost"
                title="Tipping at close only"
                tooltip="Only prompt for tip when the user has selected to close out tab. Tip field will not be displayed on cart/basket page."
                v-model="value.tippingAtCloseOnly"
            />
            <checkbox-row
                v-model="value.autoAddTipOnClose"
                title="Auto add tip on auto close"
                tooltip="Will the system auto add a tip if the system auto closes a check?"
            />
            <text-field-row
                v-if="value.autoAddTipOnClose"
                title="Auto close tip %"
                tooltip="If the tab auto closes, what tip percentage would you like automatically added to the tab?"
                v-model="value.autoCloseTip"
            />
            <checkbox-row
                title="Employee Discount"
                tooltip="Will employee discounts be enabled at this location?"
                v-model="value.employeeDiscount"
            />
            <checkbox-row
                v-if="value.employeeDiscount && isSSP"
                title="Display discount advertisement on launch screen"
                v-model="value.displayDiscountAdvertisement"
            />
            <checkbox-row
                title="Existing tab advertisement"
                v-model="value.existingTabAdvertisement"
            />
            <checkbox-row
                title="Auto receipt"
                v-model="value.autoReceipt"
            />
            <checkbox-row
                v-model="value.textReceipt"
                title="Text receipt"
                tooltip="Will the site deliver a receipt via text? This will require the system to prompt for the users phone number"
            />
            <checkbox-row
                title="Static Upsell and Add on"
                tooltip="Will upsell and Add on displayed be dynamic or static. Dynamic is recommended."
                v-model="value.staticUpsellAndAddOn"
            />
            <checkbox-row
                title="Is this a production store (not lab)"
                v-model="value.isProductionStore"
            />
            <file-input-row
                title="Email Receipt Logo"
                v-model="value.emailReceiptLogo"
            />
            <radio-group-row
                v-model="value.surveyLinkOrStarRating"
                :items="[{value: 'surveyLink', label: 'Survey Link'}, {value: 'starRating', label: 'Star Rating'}]"
                title="Thank You page"
            />
            <text-field-row
                v-if="value.surveyLinkOrStarRating === 'surveyLink'"
                v-model="value.surveyUrl"
                title="Survey URL"
            />
            <text-field-row
                title="Privacy URL"
                v-model="value.privacyUrl"
            />
            <text-field-row
                title="Terms URL"
                v-model="value.termsUrl"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
import CheckboxRow from "components/create_store/fields/CheckboxRow";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";
import TextFieldRow from "components/create_store/fields/TextFieldRow";
import FileInputRow from "components/create_store/fields/FileInputRow";

export default {
    name: "ConfigurationSection",
    props: {
        value: {
            type: Object,
            required: true,
        },
        isHMSHost: {
            type: Boolean,
            required: true,
        },
        isSSP: {
            type: Boolean,
            required: true,
        }
    },
    components: {
        FieldRow,
        CheckboxRow,
        RadioGroupRow,
        TextFieldRow,
        FileInputRow,
    },
    data() {
        return {
            newAllergyTextModalVisibility: false,
            tempAllergyText: null,
            allergyTextItems: [
                {text: 'US', value: 'US'},
                {text: 'UK', value: 'UK'},
                {text: 'New', value: 'new'},
            ],
        }
    },
    methods: {
        onAllergyUpdate(val) {
            if (val === 'new') {
                this.tempAllergyText = null;
                this.newAllergyTextModalVisibility = true;
            }
        },
        saveAndSelectAllergyText(text) {
            this.allergyTextItems.unshift({
                text: text, value: text,
            });
            this.value.allergyText = text;
            this.$emit("change", this.value);
            this.newAllergyTextModalVisibility = false;
        }
    }
}
</script>

<style scoped lang="scss">
    @import "/src/pages/create_store/wizard/section";
</style>
